import React from "react";
import { Icon } from "@iconify/react";
import linkedinCircle from "@iconify/icons-jam/linkedin-circle";
import youtubeCircle from "@iconify/icons-jam/youtube-circle";
import instagramCircle from "@iconify/icons-jam/instagram";

export default function Footer() {
	return (
		<div className="footer">
			<div className="footer-content">
				<p>Let's Connect</p>
				<div className="footer-icons">
					<a
						href="https://www.linkedin.com/company/getcadskills/"
						target="_blank"
						rel="noreferrer"
					>
						<Icon className="f-icon" icon={linkedinCircle} />
					</a>
					<a
						href="https://www.instagram.com/getcadskills/"
						target="_blank"
						rel="noreferrer"
					>
						<Icon className="f-icon" icon={instagramCircle} />
					</a>
					<a
						href="https://www.youtube.com/channel/UCZnY4o_teCfnIsS0Yu7hA7w"
						target="_blank"
						rel="noreferrer"
					>
						<Icon className="f-icon" icon={youtubeCircle} />
					</a>
				</div>
				<p> &copy; Cademia {new Date().getFullYear()}</p>
			</div>
		</div>
	);
}
