import React from "react";
import logo from "../img/cademia-logo-nb.png";

export default function Hero() {
	return (
		<>
			<div className="hero">
				<img src={logo} alt="Cademia-logo" className="logo" />
				<p className="hero-text">
					Master CAD Skills and Propel your Engineering Career!
				</p>
			</div>
			<div className="intro-text">
				<p>
					We know one size does not fit all.
					<br />
					We have therefore curated different learning approaches
					<br />
					to ensure you find your fit.
				</p>
			</div>
		</>
	);
}
