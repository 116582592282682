import React from "react";
import { Reviews as review } from "../Helpers/TestimonialScripts";

export default function Testimonials() {
	return (
		<div className="testimonials-box">
			<div className="testimonials">
				<h3>Testimonials</h3>
				<p>{review.script}</p>
				<p>
					<strong>{review.name}</strong>
				</p>
			</div>
		</div>
	);
}
