import React from "react";
import PosterCard from "../Components/PosterCard";
import civil3d070222 from "../img/7-11civil3dwebinar.jpeg";
import revitrobot140222 from "../img/revit-robot-poster.jpeg";
import civil3d02of2022 from "../img/civil3d2706to01072022.jpeg";
import revitrobot02of2022 from "../img/revitrobot0407to15072022.jpeg";

export default function VirtualTraining() {
	return (
		// <div className="placeholder-block">
		// 	<h2>Virtual Training</h2>
		// 	<p>Google form for now with preselected dates</p>
		// 	<p>Coming soon!</p>
		// </div>
		<div className="webinars">
			<h2>Upcoming Trainings</h2>
			<p>
				Book your spot by calling the number on the poster
				<br />
			</p>
			<div className="postercards-container">
				<PosterCard posterUrl={civil3d02of2022} />
				<PosterCard posterUrl={revitrobot02of2022} />
			</div>
			<h2> Previous Trainings</h2>
			<p>
				Follow the links below to view previous trainings <br />
			</p>
			<div className="postercards-container">
				<PosterCard isPast={true} posterUrl={civil3d070222} />
				<PosterCard isPast={true} posterUrl={revitrobot140222} />
			</div>
		</div>
	);
}
