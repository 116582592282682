import React from "react";

export default function About() {
	return (
		<div className="placeholder-block">
			<h2>About Cademia</h2>
			<p>
				Technology has in a good way influenced the way engineering design is
				conducted and how engineering drawings are produced as well as
				inter-disciplinary collaboration within the built environment community.
				<br />
				<br />
				We exist to bridge the gap between your busy engineering schedule and
				the need to gain these indispensable skills. Try us today.
			</p>
			<p>
				Cademia is made up of and ran by registered and practising Engineers{" "}
			</p>
			<p></p>
		</div>
	);
}
