import onsiteimg from "../img/onsite-training.jpg";
import virtualimg from "../img/virtual-training.jpg";
import coursesimg from "../img/online-course.jpg";

export const CardContent = {
	onsite: {
		title: "On-Site Training",
		img: onsiteimg,
		text: "You don’t move an inch, we come to you and train your engineering team at the comfort of your office",
		buttonText: "Book Training",
	},
	courses: {
		title: "Courses",
		img: coursesimg,
		text: "Learn at your own pace, any time, anywhere. ",
		buttonText: "Get Started",
	},
	virtual: {
		title: "Virtual Training",
		img: virtualimg,
		text: "Get a virtual one-on-one training with an instructor plus fellow learners for an interactive session. Learn, ask questions, network.",
		buttonText: "Book a Session",
	},
	paths: {
		title: "Learning Paths",
	},
};

export const LearningPaths = [
	"AutoCAD",
	"AutoCAD CIVIL 3D",
	"Revit",
	"Robot Structural Analysis",
	"WaterCAD",
	"Storm and Sewer Analysis",
];
