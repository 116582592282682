import React from "react";

export default function OnSiteTraining() {
	return (
		<div className="placeholder-block">
			<h2>On Site Training</h2>
			<p>
				Would you like us to conduct a training on your CAD technology of choice
				<br />
				from the comfort of your office?
				<br />
				<br />
				Drop us a mail on <code>getcadskills@gmail.com</code>
			</p>
			<p></p>
		</div>
	);
}
