import React from "react";
import {
	BodyContentCard1 as CardA,
	BodyContentCard2 as CardB,
} from "./BodyContentCard";
import { CardContent as data } from "../Helpers/CardData";
import { LearningPaths as lpaths } from "../Helpers/CardData";

export default function Body() {
	return (
		<div className="main-content-box">
			<div className="main-content">
				<CardA
					text={data.onsite.text}
					image={data.onsite.img}
					bText={data.onsite.buttonText}
					title={data.onsite.title}
					link="/onsite-training"
				/>
				<CardB
					text={data.courses.text}
					image={data.courses.img}
					bText={data.courses.buttonText}
					title={data.courses.title}
					link="/courses"
				/>
				<CardA
					text={data.virtual.text}
					image={data.virtual.img}
					bText={data.virtual.buttonText}
					title={data.virtual.title}
					link="/virtual-training"
				/>
				<CardB text={lpaths} title={data.paths.title} />
			</div>
		</div>
	);
}
