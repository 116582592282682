import React from "react";
export function BodyContentCard1({ image, text, bText, title, link }) {
	return image !== undefined ? (
		<div className="main-content-card">
			<div className="img-box">
				<img className="card-img" src={image} alt="mbisha" />
			</div>
			<div className="card-text">
				<h3>{title}</h3>
				<p>{text}</p>
				<a href={link}>
					<button className="card-button">{bText}</button>
				</a>
			</div>
		</div>
	) : (
		<div className="main-content-card text-only">
			<h3>{title}</h3>
			<p>
				We have curated content and expert trainers in the following packages:
			</p>
			<ul>
				{text.map((p) => (
					<li>{p}</li>
				))}
			</ul>
		</div>
	);
}

export function BodyContentCard2({ image, text, bText, title, link }) {
	return image !== undefined ? (
		<div className="main-content-card">
			<div className="card-text">
				<h3>{title}</h3>
				<p>{text}</p>
				<a href={link}>
					<button className="card-button">{bText}</button>
				</a>
			</div>
			<div className="img-box">
				<img className="card-img" src={image} alt="mbisha" />
			</div>
		</div>
	) : (
		<div className="main-content-card text-only">
			<h3>{title}</h3>
			<p>
				We have curated content and expert trainers in the following packages:
			</p>
			<ul>
				{text.map((p) => (
					<li>{p}</li>
				))}
			</ul>
		</div>
	);
}
