import LandingPage from "./Pages/LandingPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./Pages/About";
import Courses from "./Pages/Courses";
import OnSiteTraining from "./Pages/OnSiteTraining";
import VirtualTraining from "./Pages/VirtualTraining";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { useState } from "react";
import HamburgerMenu from "./Components/HamburgerMenu";

function App() {
	const [open, setOpen] = useState(false);

	return (
		<Router>
			<Navbar open={open} setOpen={setOpen} />
			{open ? <HamburgerMenu open={open} setOpen={setOpen} /> : ""}
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/about" element={<About />} />
				<Route path="/courses" element={<Courses />} />
				<Route path="/onsite-training" element={<OnSiteTraining />} />
				<Route path="/virtual-training" element={<VirtualTraining />} />
			</Routes>
			<Footer />
		</Router>
	);
}

export default App;
