import React from "react";
import { Link } from "react-router-dom";

export default function HamburgerMenu({ open, setOpen }) {
	return (
		<div className="nav-ham">
			<Link to="/about" onClick={() => setOpen(!open)}>
				About
			</Link>
			<Link to="/courses" onClick={() => setOpen(!open)}>
				Courses
			</Link>
			<Link to="/onsite-training" onClick={() => setOpen(!open)}>
				On-Site Training
			</Link>
			<Link to="/virtual-training" onClick={() => setOpen(!open)}>
				Virtual Training
			</Link>
		</div>
	);
}
