import React from "react";
import { Icon } from "@iconify/react";
import alignJustify from "@iconify/icons-jam/align-justify";
import homeIcon from "@iconify/icons-jam/home";
import closeIcon from "@iconify/icons-jam/close";
import { Link } from "react-router-dom";

export default function Navbar({ open, setOpen }) {
	return (
		<div className="navbar">
			<div className="nav-content-box">
				<div className="nav-links">
					<Link to="/">
						<Icon
							className="home-icon"
							icon={homeIcon}
							onClick={() => setOpen(false)}
						/>
					</Link>
				</div>
				{open ? (
					<Icon
						className="nav-right-close"
						icon={closeIcon}
						onClick={() => setOpen(!open)}
					/>
				) : (
					<Icon
						className="nav-right-menu"
						icon={alignJustify}
						onClick={() => setOpen(!open)}
					/>
				)}
				<div className="nav-links nav-right">
					<Link to="/about">About</Link>
					<Link to="/courses">Courses</Link>
					<Link to="/onsite-training">On-Site Training</Link>
					<Link to="/virtual-training">Virtual Training</Link>
				</div>
			</div>
		</div>
	);
}
