import React from "react";

export default function Courses() {
	return (
		<div className="placeholder-block">
			<h2>Courses</h2>

			<p>
				We are carefully crafting concise courses on various CAD technologies{" "}
				<br />
				So that you can learn at your own pace with no location restrictions
			</p>
			<p>Coming soon!</p>
		</div>
	);
}
