import React from "react";

function PosterCard({ posterUrl, isPast }) {
	return (
		<div className="poster-card">
			<img className="poster" src={posterUrl} alt="event poster" />
			{isPast ? (
				<a
					className="poster-url"
					href="https://getcadskills.teachable.com/courses"
					target="_blank"
					rel="noreferrer"
				>
					<button className="poster-button">View Training Recordings</button>
				</a>
			) : (
				""
			)}
		</div>
	);
}

export default PosterCard;
