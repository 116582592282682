import React from "react";
import Body from "../Components/Body";
import Hero from "../Components/Hero";
import Testimonials from "../Components/Testimonials";

export default function LandingPage() {
	return (
		<div>
			<Hero />
			<Body />
			<Testimonials />
		</div>
	);
}
